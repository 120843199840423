import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { handleAppendQueryToUrl } from "../utils";

const handleSortingIconClicks = ({
  history,
  key,
  currentSortBy,
  currentSortingKey,
}) => {
  const newSortBy =
    currentSortingKey === key
      ? currentSortBy === "asc"
        ? "desc"
        : "asc"
      : "asc"; // Default to asc on first click for new columns

  handleAppendQueryToUrl({
    history,
    queryName: "s",
    queryValue: key,
  });
  handleAppendQueryToUrl({
    history,
    queryName: "order",
    queryValue: newSortBy,
  });
};

const handleRenderTableHeadingWithSortIcons = ({
  history,
  hasViewToReportedTab,
}) => {
  const search = history?.location?.search;
  const searchParams = new URLSearchParams(search);
  const currentSortingKey = searchParams.get("s");
  const currentSortBy = searchParams.get("order");

  const tableHeading = [
    {
      id: 1,
      headline: "Reviews",
      display: true,
    },
    {
      id: 1.2,
      headline: "Reported",
      display: hasViewToReportedTab,
    },
    {
      id: 2,
      headline: "Rating",
      display: true,
      key: "ratings",
      sortIcon:
        currentSortingKey === "ratings" && currentSortBy === "asc" ? (
          <FontAwesomeIcon icon={faArrowUp} className="!text-[11px]" />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} className="!text-[11px]" />
        ),
      onClick: () =>
        handleSortingIconClicks({
          history,
          key: "ratings",
          currentSortBy,
          currentSortingKey,
        }),
    },
    {
      id: 3,
      headline: "Publish Date",
      key: "publish_date",
      display: true,
      sortIcon:
        currentSortingKey === "publish_date" && currentSortBy === "asc" ? (
          <FontAwesomeIcon icon={faArrowUp} className="!text-[11px]" />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} className="!text-[11px]" />
        ),
      onClick: () =>
        handleSortingIconClicks({
          history,
          key: "publish_date",
          currentSortBy,
          currentSortingKey,
        }),
    },
    {
      id: 4,
      headline: "Thumbnail",
      display: true,
    },
    {
      id: 5,
      headline: "Series ID",
      display: true,
      key: "series_id",
      sortIcon:
        currentSortingKey === "series_id" && currentSortBy === "asc" ? (
          <FontAwesomeIcon icon={faArrowUp} className="!text-[11px]" />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} className="!text-[11px]" />
        ),
      onClick: () =>
        handleSortingIconClicks({
          history,
          key: "series_id",
          currentSortBy,
          currentSortingKey,
        }),
    },
  ];

  return tableHeading;
};

const responseDefaultState = {
  feedbacks: [],
  nPages: 0,
  shows: [],
  hasMore: false,
};

const filtersState = {
  reaction: [
    { id: 0, title: "like", displayTitle: "Like" },
    { id: 1, title: "dislike", displayTitle: "Dislike" },
    { id: 2, title: "comment", displayTitle: "Comment Only" },
  ],
  reported: [
    { id: 3, title: "true", displayTitle: "Yes" },
    { id: 2, title: "false", displayTitle: "No" },
  ],
};

const reportedReviewState = {
  openModal: false,
  review: "",
  reason: "",
  id: "",
};

export {
  responseDefaultState,
  filtersState,
  reportedReviewState,
  handleRenderTableHeadingWithSortIcons,
};
