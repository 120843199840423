import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";
import { responseDefaultState } from "../../utils/constants/Feedback";
import { apiGateway } from "../../utils/config";
import { errorState } from "../../utils/constants";
import {
  handleAppendFiltersToUrl,
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
  handleRemoveParticularFilter,
} from "../../utils/utils";
import UIListing from "./UIListing";
import CustomSearch from "../Common/CustomSearch";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AlertComponent from "../Alert-Messages/alert-component.component";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";
import ShowPills from "../Common/ShowPills";
import ShowPillsSkeleton from "../Skeletons/ShowPillsSkeleton";
import FeedbackListingSkeleton from "../Skeletons/FeedbackListingSkeleton";
import FilterButton from "../Common/FilterButton";
import FilterDrawer from "./FilterDrawer";
import QueryValueChip from "../Common/QueryValueChip";
import useAllQueryParams from "../../hooks/useAllQueryParams";

const Feedback = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [message, setMessage] = useState(errorState);
  const [selectedFilters, setSelectedFilters] = useState({
    reported: [],
    reaction: [],
  });
  const [responseData, setResponseData] = useState(responseDefaultState);
  const history = useHistory();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const allQueryParams = useAllQueryParams({
    excludedParams: ["page", "show"],
  });
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [, setPageNumber] = useState(searchParamPage);
  const searchParamSearchQuery = searchParams?.get("query") || "";
  const searchParamSortingKey = searchParams?.get("s") || "";
  const searchParamSortBy = searchParams?.get("order") || "";
  const searchParamShowSlug = searchParams?.get("show") || "";
  const searchParamReportedFilter = searchParams?.getAll("reported") || [];
  const searchParamReactionFilter = searchParams?.getAll("reaction") || [];

  const handleGetFeedback = async ({ query, pageArg }) => {
    try {
      setIsLoading(true);
      // Append filters to URL
      const appendReactionFilter = searchParamReactionFilter
        ?.map((item) => `&filter_by=${item}`)
        .join("");

      const appendReportedFilter = searchParamReportedFilter
        .map((item) => `&is_reported=${item}`)
        .join("");

      let url = `${apiGateway}/api/v1/cms/creator-feedback/?page=${pageArg}&page_size=20&q=${query}${
        searchParamSortingKey
          ? `&s=${searchParamSortingKey}&order=${searchParamSortBy}`
          : ""
      }${searchParamShowSlug ? `&show_slug=${searchParamShowSlug}` : ""}`;

      if (appendReactionFilter) url += appendReactionFilter;
      if (appendReportedFilter) url += appendReportedFilter;

      const response = await axiosPrivate.get(url);
      const data = response?.data;
      if (data) {
        const feedbacks = data?.feedbacks;
        const shows = data?.shows;
        const nPages = data?.n_pages;
        const hasMore = data?.has_more;
        setResponseData({ feedbacks, nPages, hasMore, shows });
        setIsLoading(false);
      }
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      setResponseData(responseDefaultState);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // onPage change
  const handlePageChange = (e, value) => {
    setPageNumber(value);
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: value });
  };

  // on hitting enter after search query
  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleAppendQueryToUrl({
        history,
        queryName: "query",
        queryValue: searchQuery,
      });
      handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    }
  };

  // On Filter change
  const handleFilterChange = (event, filterType) => {
    const { value, checked, type } = event.target;
    setSelectedFilters((prevSelectedFilters) => {
      const updatedFilters = { ...prevSelectedFilters };
      if (type === "checkbox") {
        if (!updatedFilters[filterType]) {
          updatedFilters[filterType] = [];
        }
        if (checked) {
          updatedFilters[filterType] = [...updatedFilters[filterType], value];
        } else {
          updatedFilters[filterType] = updatedFilters[filterType].filter(
            (filter) => filter !== value
          );
        }
      }
      return updatedFilters;
    });
  };

  // Apply filters function
  const handleApplyFilters = () => {
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 }); // setting to page 1
    Object.keys(selectedFilters).forEach((filterName) => {
      const filterValues = selectedFilters[filterName];
      if (filterValues?.length > 0) {
        handleAppendFiltersToUrl({
          filters: filterValues,
          history,
          filterName,
        });
      }
    });
    setOpenFilters(false);
  };

  //Remove Filters function
  const handleRemoveFilter = ({ filterName, filterToRemove }) => {
    handleRemoveParticularFilter({ filterName, filterToRemove, history });
  };

  // when clicked on particular show
  const handleOnShowClick = ({ showSlug }) => {
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    handleAppendQueryToUrl({
      history,
      queryName: "show",
      queryValue: showSlug,
    });
    handleDeleteQueryFromUrl({
      history,
      queryNames: ["page", "s", "order"],
    });
  };

  // when clicked on All Show CTA Pill
  const handleClickOnAllShowCTA = () => {
    handleDeleteQueryFromUrl({
      history,
      queryNames: ["page", "s", "order", "show"],
    });
  };

  // useEffect handling all the API calls based on sort and filters
  useEffect(() => {
    handleGetFeedback({
      query: searchParamSearchQuery,
      pageArg: searchParamPage,
    });
  }, [
    searchParamSearchQuery,
    searchParamPage,
    searchParamSortingKey,
    searchParamSortBy,
    searchParamShowSlug,
    JSON.stringify(searchParamReactionFilter), // render the useEffect when any of the value changes in the array
    JSON.stringify(searchParamReportedFilter), // render the useEffect when any of the value changes in the array
  ]);

  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  return (
    <div>
      <div>
        <div>
          <div className="flex gap-x-3">
            <div className="lg:w-[50%]">
              <CustomSearch
                placeHolder="Search by video id or title"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleInputKey={handleInputKey}
                handleClearInput={() => {
                  setSearchQuery("");
                  handleDeleteQueryFromUrl({ history, queryNames: ["query"] });
                }}
              />
            </div>
            <FilterButton
              onClick={() => {
                setOpenFilters(true);
                setSelectedFilters({
                  reaction: [...searchParamReactionFilter],
                  reported: [...searchParamReportedFilter],
                });
              }}
            />
          </div>

          <h6 className="!text-[20px] mt-3 font-medium">Feedbacks</h6>

          {/* Mapping all the query params as chips */}
          <div className="flex !gap-x-2 mt-1">
            {Object.entries(allQueryParams).map(([key, values]) => (
              <div key={key}>
                <div className="flex flex-wrap gap-x-2">
                  {values.map((item, index) => (
                    <QueryValueChip
                      key={index}
                      label={
                        item === "true"
                          ? "Reported"
                          : item === "false"
                          ? "Not reported"
                          : item
                      }
                      onDelete={() => {
                        handleRemoveFilter({
                          filterName: key,
                          filterToRemove: item,
                        });
                      }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Show Listing Pills */}
          {isLoading ? (
            <div className="mt-2">
              <ShowPillsSkeleton />
              <div className="mt-2">
                <FeedbackListingSkeleton />
              </div>
            </div>
          ) : (
            <div>
              {responseData?.shows?.length > 0 ? (
                <div className="mt-2">
                  <ShowPills
                    shows={responseData?.shows}
                    searchParamShowSlug={searchParamShowSlug}
                    handleClickOnAllShowCTA={handleClickOnAllShowCTA}
                    handleOnShowClick={handleOnShowClick}
                  />
                </div>
              ) : null}

              {/* Table UI with error handling & Pagination */}
              {responseData?.feedbacks?.length === 0 ? (
                <div className="!bg-white h-[calc(100vh-200px)] flex items-center justify-center mt-2">
                  <NoFeedbackSvj
                    className="w-[250px] mx-auto"
                    displayText={
                      isError
                        ? "Something Went Wrong, Please Try Later"
                        : "You don’t have any feedbacks on this video yet"
                    }
                    displayTextClass="mt-2 text-gray-400 text-[14px]"
                  />
                </div>
              ) : (
                <div className="!overflow-x-auto rounded-md !bg-white mt-2 pb-4 border-t">
                  <UIListing data={responseData?.feedbacks} />
                  {responseData?.nPages > 1 && (
                    <Pagination
                      className="flex justify-center mt-5"
                      shape="rounded"
                      count={responseData?.nPages}
                      page={searchParamPage}
                      onChange={(e, val) => handlePageChange(e, val)}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Filter Drawer */}
      <FilterDrawer
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
        selectedFilters={selectedFilters}
        handleFilterChange={handleFilterChange}
        handleApplyFilters={handleApplyFilters}
        handleClearAllFilters={() => {
          handleDeleteQueryFromUrl({
            history,
            queryNames: ["reaction", "reported"],
          });
        }}
      />

      {/* Alert */}
      <AlertComponent
        message={message?.error}
        type={message?.type}
        setAlertNotification={() => setMessage({ error: "", type: "failed" })}
      />
    </div>
  );
};

export default Feedback;
