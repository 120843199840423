import React from "react";
import { Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectConfigFilters } from "../../redux/config/config.selector";
import CustomFilterDrawer from "../Common/CustomFilterDrawer";

const FilterDrawer = ({
  openFilters,
  selectedFilters,
  setOpenFilters,
  handleSelectedFilter,
  handleClearAllFilters,
  handleApplyFilters,
}) => {
  const configFilters = useSelector(selectConfigFilters);
  return (
    <div>
      {openFilters && (
        <CustomFilterDrawer
          open={openFilters}
          handleClose={() => setOpenFilters(false)}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={() => {
            handleClearAllFilters();
            setOpenFilters(false);
          }}
        >
          <div className="">
            <h5 className="filter-header px-2">Categories</h5>
            <Form.Group
              className="filters-list-series custom-drawer-list-series px-2"
              as={Row}
            >
              {configFilters.categories?.map((item, index) => {
                const filterValue = `${item.title}-${item.id}`;
                return (
                  <Form.Check
                    key={item.id + `${index}`}
                    type="checkbox"
                    name="categoryFilter"
                    onChange={handleSelectedFilter}
                    value={filterValue}
                    label={item.title}
                    checked={selectedFilters?.includes(filterValue)}
                    className="form-col col-md-6 form-checkbox-align"
                  />
                );
              })}
            </Form.Group>
          </div>
        </CustomFilterDrawer>
      )}
    </div>
  );
};

export default FilterDrawer;
