import React from "react";
import CreatorsListing from "../../components/Creator";

function CreatorPage() {
  return (
    <div className="mt-3">
      <CreatorsListing />
    </div>
  );
}

export default CreatorPage;
