import React, { useRef, useState } from "react";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiGateway } from "../../../utils/config";
import { SERIES_V1_1 } from "../../../utils/constants";
import { CircularProgress } from "@mui/material";
import placeholder from "../../../assests/images/placeholder1.png";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import VideoPlayerCustom from "../../VideoPlayer/VideoPlayerCustom";
import NoFeedbackSvj from "../../../assests/images/Feedback/NoFeedback";
import CustomModal from "../CustomModal";

// Made a common component of from old code to play the video
const VideoPlayOnSeriesClick = ({
  thumbnail,
  title,
  n_units,
  slug,
  thumbnailClassName,
  playIconClassName,
  thumbnailWrapperClassName,
  setMessage,
}) => {
  const [playIndex, setPlayIndex] = useState(0);
  const [videoSource, setVideoSource] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [hasMoreVideos, setHasMoreVideos] = useState(false);
  const [showVideosLoading, setShowVideosLoading] = useState(true);
  const [seriesVideos, setSeriesVideos] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const axiosPrivate = useAxiosPrivate();
  const playerRef = useRef(null);
  const videoRef = useRef();

  // videoPlay next
  const handlePlayNextVideo = async (currentPlayIndex, data) => {
    const index = currentPlayIndex >= 0 ? currentPlayIndex : playIndex + 1;
    if (index >= 0 && index < data?.length) {
      const video = data[index];
      const source = video?.content?.h264_media_url
        ? video?.content?.h264_media_url
        : video?.content?.url;
      setVideoSource((prev) => source);
      setVideoTitle((prev) => video?.title);
      setPlayIndex((prev) => index);
      setShowVideo(true);
    } else if (hasMoreVideos) {
      setPageNumber((prev) => pageNumber + 1);
    }
  };

  // previous video play function
  const handlePlayPreviousVideo = () => {
    const index = playIndex - 1;
    if (index >= 0 && index < seriesVideos?.length) {
      const video = seriesVideos[index];
      setVideoSource(video.content.h264_media_url || video.content.url);
      setVideoTitle(video?.title);
      setPlayIndex(index);
      setShowVideo(true);
    }
  };

  // on video end
  const handleOnVideoEnd = (seriesVideos) => {
    handlePlayNextVideo(-1, seriesVideos);
  };

  // on Video Close Modal
  const handleVideoModal = (videoSource, videoTitle, playIndex) => {
    if (showVideo) {
      setShowVideo(false);
      setPlayIndex(0);
      setVideoSource(false);
      return;
    } else {
      setVideoSource(videoSource);
      setVideoTitle(videoTitle);
      setShowVideo((prev) => !prev);
      setPlayIndex(playIndex);
      playerRef.current = null;
    }
  };

  const handleGetVideos = async (page, slug) => {
    try {
      setShowVideo(true);
      setShowVideosLoading(true);
      const url = `${apiGateway}${SERIES_V1_1}${slug}/units/?page=1&lang=en&page_size=${page}`;
      const { data, status } = await axiosPrivate.get(url);
      if (data && status === 200) {
        setHasMoreVideos(data?.has_more);
        if (data?.content_units) {
          setSeriesVideos([...data.content_units]);
          const video = data.content_units[0];
          setPlayIndex(0);
          handlePlayNextVideo(0, data.content_units);
        }
      }
    } catch (error) {
      setShowVideosLoading(false);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    } finally {
      setShowVideosLoading(false);
    }
  };

  const playAllVideos = (n_unit, s_slug) => {
    handleGetVideos(n_unit, s_slug);
  };

  return (
    <div>
      <div
        className={` relative cursor-pointer ${thumbnailWrapperClassName}`}
        onClick={() => n_units > 0 && playAllVideos(n_units, slug)}
      >
        <img
          src={thumbnail || placeholder}
          alt={title}
          className={`w-full h-full !cursor-pointer ${thumbnailClassName}`}
        />
        {n_units > 0 && (
          <div
            className="absolute inset-0 flex items-center justify-center"
            style={{ pointerEvents: "none" }}
          >
            <FontAwesomeIcon
              icon={faPlayCircle}
              className={`text-white text-4xl ${playIconClassName} opacity-[85%]`}
            />
          </div>
        )}
      </div>

      <CustomModal
        title={
          videoTitle?.length > 60
            ? videoTitle?.substr(0, 60) + "..."
            : videoTitle
        }
        show={showVideo}
        onHide={() => setShowVideo(false)}
        isButtonsRequired={false}
      >
        <div className="!w-[67%] mx-auto  modal-video-player">
          <div className="icon">
            {playIndex > 0 && (
              <div className="prev-icon" onClick={handlePlayPreviousVideo}>
                <FontAwesomeIcon
                  size="2x"
                  className="icon"
                  icon={faChevronCircleLeft}
                />
              </div>
            )}
            {(playIndex < seriesVideos.length - 1 || hasMoreVideos) && (
              <div
                className="next-icon"
                onClick={() => handlePlayNextVideo(-1, seriesVideos)}
              >
                <FontAwesomeIcon
                  size="2x"
                  className="icon"
                  icon={faChevronCircleRight}
                />
              </div>
            )}
          </div>
          {showVideosLoading ? (
            <div className="center h-full">
              <CircularProgress className="!text-baseBlueColor" />
            </div>
          ) : videoSource ? (
            <VideoPlayerCustom
              showNextPrev={true}
              controlProps={{
                playIndex: playIndex,
                seriesVideos: seriesVideos,
                hasMoreVideos: hasMoreVideos,
                handlePlayPreviousVideo: handlePlayPreviousVideo,
                handlePlayNextVideo: (seriesVideos) =>
                  handlePlayNextVideo(-1, seriesVideos),
              }}
              src={videoSource}
              controls={true}
              muted={false}
              autoplay={true}
              onEnded={handleOnVideoEnd}
              onTimeUpdate={() => {}}
              videoRef={videoRef}
              currentTime={0}
            />
          ) : (
            <div className="h-full flex items-center justify-center">
              <NoFeedbackSvj
                className="w-[200px] mx-auto mt-40"
                displayText="Source not found or Something went wrong!!"
                displayTextClass="text-[12px] text-gray-500 mt-1"
              />
            </div>
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default VideoPlayOnSeriesClick;
