import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const ShowPills = ({
  shows,
  searchParamShowSlug,
  handleClickOnAllShowCTA,
  handleOnShowClick,
}) => {
  const showRefs = useRef([]);
  const containerRef = useRef(null);
  const [showButtons, setShowButtons] = useState(false); // State to track button visibility

  // Clear the ref array for a new render
  showRefs.current = shows?.map(
    (_, i) => showRefs?.current[i] || React?.createRef()
  );

  // Scroll left function
  const handleScrollLeft = () => {
    if (containerRef?.current) {
      containerRef.current.scrollBy({
        left: -400,
        behavior: "smooth",
      });
    }
  };

  // Scroll right function
  const handleScrollRight = () => {
    if (containerRef?.current) {
      containerRef.current.scrollBy({
        left: 400,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const showIndex = shows?.findIndex(
      (item) => item?.slug === searchParamShowSlug
    );
    if (showIndex !== -1 && showRefs?.current[showIndex]) {
      showRefs.current[showIndex]?.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }

    // Check if the container overflows
    if (containerRef?.current) {
      const isOverflowing =
        containerRef?.current?.scrollWidth > containerRef?.current?.clientWidth;
      setShowButtons(isOverflowing);
    }
  }, [searchParamShowSlug, shows]);

  return (
    <div className="flex items-center">
      {showButtons && (
        <button onClick={handleScrollLeft} className="mr-2">
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            className="text-baseBlueColor text-[18px]"
          />
        </button>
      )}

      {/* Fixed All Shows Button */}
      <button
        onClick={handleClickOnAllShowCTA}
        className={`border cursor-pointer min-w-fit px-4 py-2 text-[12px] rounded-[34px] ${
          searchParamShowSlug
            ? "!border-gray-300"
            : "!border-baseBlueColor text-baseBlueColor font-medium bg-baseFillColor"
        }`}
      >
        All Shows
      </button>

      <div
        ref={containerRef}
        className="flex items-center max-w-screen !overflow-x-auto gap-x-2 ml-2"
        id="HideScroll"
      >
        {shows?.map((item, index) => {
          return (
            <div
              key={item?.id}
              ref={showRefs.current[index]} // Attach the ref to each item
              onClick={() =>
                handleOnShowClick({ showSlug: item?.slug || "all" })
              }
              className={`border cursor-pointer min-w-fit px-4 py-2 text-[12px] rounded-[34px] ${
                searchParamShowSlug === item?.slug
                  ? "!border-baseBlueColor text-baseBlueColor font-medium bg-baseFillColor"
                  : "!border-gray-300 hover:!bg-slate-200 font-normal"
              }`}
            >
              {item?.title}
            </div>
          );
        })}
      </div>
      {showButtons && (
        <button onClick={handleScrollRight} className="ml-2">
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            className="text-baseBlueColor text-[18px]"
          />
        </button>
      )}
    </div>
  );
};

export default ShowPills;
