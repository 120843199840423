import React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { handleFormatDate } from "../../utils/utils";
import {
  handleTopicsStatusColor,
  handleTopicsTableHeadline,
} from "../../utils/constants/Topics";
import useZustandStore from "../../zustandStore/useZustandStore";

const TopicsListingTable = ({
  data,
  handleDeleteTopic,
  handleApproveOrDecline,
}) => {
  const { internalTeamAccessGlobalState } = useZustandStore();
  const hasAccessToActions = internalTeamAccessGlobalState;

  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-gray-700 !bg-gray-100 !border-b">
          <tr>
            {handleTopicsTableHeadline({
              hasAccessToActions,
            })?.map((item) => {
              return (
                item?.display && (
                  <th
                    scope="col"
                    className={`px-3 py-3 whitespace-nowrap !text-[12px] text-gray-700 !bg-gray-100 z-[99] !font-medium `}
                    key={item?.title}
                  >
                    {item?.title}
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => {
            const isApproved = item?.status === "approved";
            const isDeclined = item?.status === "decline";
            const isDeleted = item?.status === "deleted";
            return (
              <tr
                key={item?.id}
                className="last:!border-none border-b text-gray-500 !font-normal text-[12px]"
              >
                <td className="px-3 py-3 text-[14px] text-black whitespace-nowrap cursor-pointer lg:whitespace-normal tracking-wide">
                  {item?.title}
                </td>

                <td className="px-3 py-3 text-[14px] whitespace-nowrap">
                  {handleFormatDate({ dateInString: item?.created_on })}
                </td>

                <td className="px-3 py-3 text-[14px]">
                  {handleFormatDate({
                    dateInString: item?.published_on,
                    showTime: false,
                  })}
                </td>

                <td className="px-3 py-3 text-[14px] !max-w-[200px] break-all break-words">
                  {item?.reference || "-"}
                </td>

                <td className="px-3 py-3 text-[12px] whitespace-nowrap">
                  <div
                    className={`w-fit px-2 py-1 rounded-xl capitalize ${handleTopicsStatusColor(
                      { status: item?.status }
                    )}`}
                  >
                    {item?.status}
                  </div>
                </td>

                {hasAccessToActions ? (
                  <td className="px-3 py-2 text-[14px] capitalize">
                    <Dropdown>
                      <Dropdown.Toggle className="dropdown-action">
                        <FontAwesomeIcon
                          className="rejection-logs ml-1"
                          icon={faEllipsisV}
                          size="xs"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {!isApproved && !isDeleted && (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() =>
                              handleApproveOrDecline({
                                topicId: item?.id,
                                status: "approve",
                              })
                            }
                          >
                            Approve
                          </Dropdown.Item>
                        )}

                        {!isDeclined && !isDeleted && (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() =>
                              handleApproveOrDecline({
                                topicId: item?.id,
                                status: "decline",
                              })
                            }
                          >
                            Decline
                          </Dropdown.Item>
                        )}

                        {isDeleted ? (
                          <Dropdown.Item className="action-menu-item">
                            No actions
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() =>
                              handleDeleteTopic({ topicId: item?.id })
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TopicsListingTable;
