import React from "react";
import RequiredStar from "./RequiredStar";

const InputTitleWithRequired = ({ title, isRequired = true, className }) => {
  return (
    <div className={`text-[14px] ${className || ""}`}>
      {title} {""}
      {isRequired && <RequiredStar />}
    </div>
  );
};

export default InputTitleWithRequired;
