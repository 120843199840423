import React from "react";
import { Drawer } from "@mui/material";
import FilterApplyCancelButtons from "./FilterApplyCancelButtons";

const CustomFilterDrawer = ({
  open,
  anchor = "right",
  children,
  childrenClassName,
  handleClose,
  handleApplyFilters,
  handleClearFilters,
}) => {
  return (
    <div>
      <Drawer
        open={open}
        onClose={handleClose}
        anchor={anchor}
        id="ThinGrayScrollBar"
      >
        <div className={` w-[350px] ${childrenClassName || ""}`}>
          {children}
        </div>
        <div className="fixed bottom-0 py-3 px-2 bg-gray-100 w-full">
          <FilterApplyCancelButtons
            handleApply={handleApplyFilters}
            handleCancel={handleClearFilters}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default CustomFilterDrawer;
