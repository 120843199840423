import React from "react";
import { Chip } from "@mui/material";
import { getDateString } from "../../utils/utils";
import { handleExperimentTableHeading } from "../../utils/constants/Experiments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import placeholder from "../../assests/images/placeholder1.png";
import CustomAnchor from "../Common/CustomAnchor";

const ExperimentsTable = ({
  data,
  isDefaultRemakeSeriesTab,
  hasAccessToActions,
  handleView,
}) => {
  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="border-b bg-gray-100">
          <tr>
            {handleExperimentTableHeading({ isDefaultRemakeSeriesTab }).map(
              (item) => {
                return (
                  item?.display && (
                    <th
                      scope="col"
                      className="px-6 py-3 !text-[12px] text-gray-700 font-medium bg-gray-100"
                      key={item.id}
                    >
                      {item.title}
                    </th>
                  )
                );
              }
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return (
              <tr
                className="text-black cursor-pointer !font-normal border-b text-[12px] last:border-none"
                key={item?.id + index}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <img
                    src={
                      item?.experiment?.image
                        ? item?.experiment?.image
                        : item?.original_image
                        ? item?.original_image
                        : placeholder
                    }
                    alt={item?.title}
                    className="!min-w-[84px] !max-w-[84px] h-[116px] rounded-lg"
                  />
                </td>
                <td
                  scope="row"
                  className="px-6 py-4 whitespace-nowrap text-[14px] tracking-wide"
                >
                  <CustomAnchor
                    displayText={
                      item?.experiment?.title
                        ? item?.experiment?.title
                        : item?.display_title
                    }
                    href={`/#/videos/${item.slug}`}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-gray-500">
                  {item?.id}
                </td>

                {!isDefaultRemakeSeriesTab && (
                  <td className="px-6 py-4 whitespace-nowrap text-[14px] text-gray-500">
                    {item?.experiment?.start_time
                      ? getDateString(
                          new Date(item?.experiment?.start_time)
                            .toISOString()
                            .split("T")[0]
                        )
                      : "-"}
                  </td>
                )}

                {!isDefaultRemakeSeriesTab && (
                  <td className="px-6 py-4 whitespace-nowrap text-[14px] text-gray-500">
                    {item?.experiment?.end_time
                      ? getDateString(
                          new Date(item?.experiment?.end_time)
                            .toISOString()
                            .split("T")[0]
                        )
                      : "-"}
                  </td>
                )}

                {isDefaultRemakeSeriesTab && (
                  <td className="px-6 py-4 whitespace-nowrap text-[14px] text-gray-500">
                    {item?.experiment?.replace_time &&
                      getDateString(
                        new Date(item?.experiment?.replace_time)
                          .toISOString()
                          .split("T")[0]
                      )}
                  </td>
                )}

                <td className="px-6 py-4 whitespace-nowrap">
                  {item?.experiment?.status ? (
                    <div
                      className={`capitalize text-[12px] w-fit text-white px-2 py-[2px] rounded-full ${
                        item?.experiment?.status === "expired"
                          ? "bg-red-500"
                          : item?.experiment?.status === "ongoing"
                          ? "bg-green-500"
                          : "bg-black"
                      }`}
                    >
                      {item?.experiment?.status}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>

                {hasAccessToActions && (
                  <td className="px-6 py-4 whitespace-nowrap text-[14px] text-gray-500">
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => handleView({ data: item })}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ExperimentsTable;
