import React from "react";
import { handleTopicsTableHeadline } from "../../utils/constants/Topics";
import useZustandStore from "../../zustandStore/useZustandStore";

const TopicsListSkeleton = ({ rows = 5, columns = 5 }) => {
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );
  const hasAccessToActions = internalTeamAccessGlobalState;
  return (
    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
      <thead className="text-gray-700 !bg-gray-100 !border-b">
        <tr>
          {handleTopicsTableHeadline({
            hasAccessToActions,
          })?.map((item) => {
            return (
              item?.display && (
                <th
                  scope="col"
                  className={`px-3 py-3 whitespace-nowrap !text-[12px] text-gray-700 !bg-gray-100 z-[99] !font-medium `}
                  key={item?.title}
                >
                  {item?.title}
                </th>
              )
            );
          })}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <tr
            key={rowIndex}
            className="last:!border-none border-b text-gray-500 !font-normal text-[12px]"
          >
            {Array.from({ length: columns }).map((_, colIndex) => (
              <td
                key={colIndex}
                className="px-3 py-3 text-[14px] whitespace-nowrap"
              >
                <div
                  className={`h-4 bg-gray-300 rounded ${
                    colIndex === 0 ? "!w-[300px]" : "w-[100px]"
                  } animate-pulse`}
                ></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TopicsListSkeleton;
