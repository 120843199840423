import React from "react";
import { Warning } from "@mui/icons-material";

const StagingAlert = () => {
  return (
    <div className="w-fit mt-1 bg-[#ec6c03] px-1 py-1 text-[8px] lg:!text-[12px] lg:!px-4 lg:py-2 rounded-lg text-white">
      <strong>
        <Warning className="!text-[14px]" />
      </strong>{" "}
      You are currently viewing the{" "}
      <span className="font-bold">staging environment.</span>{" "}
      <span>
        If you are looking for the live CMS, please visit:{" "}
        <a
          href="https://cms.seekhoapp.com"
          target="_blank"
          rel="noopener noreferrer"
          className="underline font-bold"
        >
          Seekho CMS
        </a>
      </span>
    </div>
  );
};

export default StagingAlert;
