import React from "react";
import { Form, Row } from "react-bootstrap";
import { filtersState } from "../../utils/constants/Feedback";
import useZustandStore from "../../zustandStore/useZustandStore";
import CustomFilterDrawer from "../Common/CustomFilterDrawer";

const FilterDrawer = ({
  openFilters,
  selectedFilters,
  setOpenFilters,
  handleFilterChange,
  handleApplyFilters,
  handleClearAllFilters,
}) => {
  const globalRoles = useZustandStore((val) => val?.globalRoles);
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );
  const isViewer = globalRoles?.viewer;
  const hasAccessToReportedFilter = internalTeamAccessGlobalState || isViewer;

  return (
    <div>
      {openFilters && (
        <CustomFilterDrawer
          open={openFilters}
          handleClose={() => setOpenFilters(false)}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={() => {
            handleClearAllFilters();
            setOpenFilters(false);
          }}
        >
          <div>
            <h5 className="filter-header px-2">Reaction</h5>
            <Form.Group
              className="filters-list-series custom-drawer-list-series px-2"
              as={Row}
            >
              {filtersState?.reaction?.map((item, index) => {
                const filterValue = `${item.title}`;
                return (
                  <Form.Check
                    key={item.id + `${index}`}
                    type="checkbox"
                    name="reactionFilter"
                    onChange={(e) => handleFilterChange(e, "reaction")}
                    value={filterValue}
                    label={item.displayTitle}
                    checked={selectedFilters?.reaction?.includes(filterValue)}
                    className="form-col col-md-6 form-checkbox-align"
                  />
                );
              })}
            </Form.Group>

            {hasAccessToReportedFilter && (
              <div>
                <h5 className="filter-header px-2">Reported</h5>
                <Form.Group
                  className="filters-list-series custom-drawer-list-series px-2"
                  as={Row}
                >
                  {filtersState?.reported?.map((item, index) => {
                    const filterValue = `${item.title}`;
                    return (
                      <Form.Check
                        key={item.id + `${index}`}
                        type="checkbox"
                        name="reportedFilter"
                        onChange={(e) => handleFilterChange(e, "reported")}
                        value={filterValue}
                        label={item.displayTitle}
                        checked={selectedFilters?.reported?.includes(
                          filterValue
                        )}
                        className="form-col col-md-6 form-checkbox-align"
                      />
                    );
                  })}
                </Form.Group>
              </div>
            )}
          </div>
        </CustomFilterDrawer>
      )}
    </div>
  );
};

export default FilterDrawer;
