import { useLocation } from "react-router-dom";

const useAllQueryParams = ({ excludedParams = [] }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Convert all params into an object with arrays for each key, excluding specified params
  const allParams = {};
  for (const [key, value] of searchParams.entries()) {
    if (!excludedParams.includes(key)) {
      // Skip keys in excludedParams
      if (allParams[key]) {
        allParams[key].push(value); // Add to the array if key already exists
      } else {
        allParams[key] = [value]; // Create a new array if key doesn't exist
      }
    }
  }

  return allParams;
};

export default useAllQueryParams;
