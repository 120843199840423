import React, { useState, useEffect } from "react";
import { Snackbar } from "@mui/material";
import { handleAlertTriggeredEvent } from "../../utils/eventFunction";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

export default function AlertComponent(props) {
  const [showAlertErrorMessage, setShowAlertErrorMessage] = useState(false);
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const { type, message, setAlertNotification } = props;

  const closeTheAlertDeleteTheMessage = () => {
    setShowAlertErrorMessage(false);
    if (props && setAlertNotification) {
      setAlertNotification("");
    }
  };

  useEffect(() => {
    if (message?.length > 0) {
      setShowAlertErrorMessage(true);
      // Triggering the event when only error
      if (type === "error" || type === "failed") {
        handleAlertTriggeredEvent({
          alertMessage: message,
          userData: currentUser,
        });
      }
    } else {
      setShowAlertErrorMessage(false);
    }
  }, [message]);

  return (
    <div>
      <Snackbar
        ClickAwayListenerProps={{ onClickAway: () => null }}
        open={showAlertErrorMessage}
        autoHideDuration={3000}
        onClose={closeTheAlertDeleteTheMessage}
      >
        <Alert
          variant="filled"
          onClose={() => {
            closeTheAlertDeleteTheMessage();
          }}
          severity={
            type === "failed" ? "error" : type?.length > 0 ? type : "info"
          }
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
