import React from "react";
import { Dropdown } from "react-bootstrap";
import { handleDefaultProfile } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Chip } from "@mui/material";
import useZustandStore from "../../zustandStore/useZustandStore";

const CategoryTable = ({
  data,
  handleEditCategory,
  handleToggleDefault,
  handleToggleActive,
}) => {
  const globalRoles = useZustandStore((val) => val?.globalRoles);
  const isAdmin = globalRoles?.admin;
  const isSuperAdmin = globalRoles?.superAdmin;

  const categoryColumnHeadings = [
    "ID",
    "Icon",
    "Title",
    "Active Status",
    "Default Status",
  ];

  // Only allowing action to Admin & SuperAdmin
  if (isAdmin || isSuperAdmin) categoryColumnHeadings.push("Actions");

  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="border-b bg-gray-100">
          <tr>
            {categoryColumnHeadings.map((item) => {
              return (
                <th
                  scope="col"
                  className="px-6 py-3 !text-[12px] text-gray-700 font-medium bg-gray-100 z-[99]"
                  key={item}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => {
            return (
              <tr
                className="text-black cursor-pointer !font-normal border-b text-[12px] last:border-none"
                key={item?.id}
              >
                <td className="px-6 py-4 whitespace-nowrap text-[15px]">
                  {item?.id}
                </td>
                <td scope="row" className="px-6 py-4 whitespace-nowrap">
                  <img
                    src={
                      item?.home_icon ||
                      handleDefaultProfile({ userName: item?.title })
                    }
                    alt={item.title}
                    className="!w-[40px] rounded-full"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[15px]">
                  {item?.title}
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center text-[15px]">
                    <div
                      class={`h-2.5 w-2.5 mt-1 rounded-full ${
                        item?.is_active ? "bg-green-500" : "bg-red-500"
                      } me-2`}
                    />
                    <p className="mt-[3px]">
                      {item?.is_active ? "Active" : "Inactive"}
                    </p>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                  <Chip
                    label={item?.is_default ? "Default" : "Not Default"}
                    variant="filled"
                    color={item?.is_default ? "success" : "error"}
                    className="px-2 !w-[85px]"
                  />
                </td>
                {(isAdmin || isSuperAdmin) && (
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Dropdown className="w-100">
                      <Dropdown.Toggle className="dropdown-action-common">
                        <FontAwesomeIcon
                          className="rejection-logs ml-1"
                          icon={faEllipsisV}
                          size="1x"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="action-menu">
                        <Dropdown.Item
                          className="action-menu-item"
                          onClick={() => {
                            handleEditCategory({ item });
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="action-menu-item"
                          onClick={() =>
                            handleToggleActive({
                              categorySlug: item?.slug,
                              status: item?.is_active,
                            })
                          }
                        >
                          {item?.is_active
                            ? "Remove from Active"
                            : "Make it Active"}
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="action-menu-item"
                          onClick={() =>
                            handleToggleDefault({
                              categorySlug: item?.slug,
                              status: item?.is_default,
                            })
                          }
                        >
                          {item?.is_default
                            ? "Remove from Default"
                            : "Make it Default"}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryTable;
