import React from "react";

const CustomInput = ({
  id,
  type,
  label,
  placeholder,
  value,
  className,
  error,
  disabled,
  required,
  min,
  onChange,
}) => {
  return (
    <input
      id={id}
      type={type}
      placeholder={placeholder || ""}
      label={label || ""}
      value={value}
      className={` text-[14px] ${error ? "border !border-red-500" : ""} ${
        className || ""
      } `}
      disabled={disabled}
      required={required}
      error={error}
      min={min}
      onChange={onChange}
    />
  );
};

export default CustomInput;
