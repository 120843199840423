import React from "react";
import ShowsTopicsComponent from "../../components/ShowsTopics";

const ShowTopics = () => {
  return (
    <div className="mt-3">
      <ShowsTopicsComponent />
    </div>
  );
};

export default ShowTopics;
