import React from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import CustomFilterDrawer from "../Common/CustomFilterDrawer";

const ExperimentFilterDrawer = ({
  openFilter,
  selectedFilters,
  handleCloseFilter,
  handleFilterChange,
  handleApplyFilters,
  handleClearFilters,
}) => {
  const configFilters = useSelector((state) => state?.config?.filters);
  return (
    <div>
      {openFilter && (
        <CustomFilterDrawer
          open={openFilter}
          handleClose={handleCloseFilter}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={handleClearFilters}
        >
          <div>
            <h5 className="filter-header px-2">Status</h5>
            <Form.Group className="px-2">
              {configFilters.experimentStatus?.map((item) => {
                const filterValue = item?.label?.toLowerCase();
                return (
                  <Form.Check
                    type="radio"
                    name="statusFilter"
                    label={item?.label}
                    value={filterValue}
                    checked={selectedFilters?.status?.includes(filterValue)}
                    onChange={(e) => handleFilterChange(e, "status")}
                    className="py-1 text-[14px]"
                  />
                );
              })}
            </Form.Group>

            <h5 className="filter-header px-2">Date</h5>
            <Form.Group className="px-2">
              {configFilters?.start_on?.map((item) => {
                return (
                  item?.status === "start_on" && (
                    <>
                      <Form.Label style={{ fontSize: 12, margin: 0 }}>
                        {item?.label}
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder={item?.label}
                        name={item?.status}
                        id={item?.status}
                        value={selectedFilters?.startDate}
                        onChange={(e) => handleFilterChange(e, "startDate")}
                      />
                    </>
                  )
                );
              })}
            </Form.Group>

            <Form.Group className="px-2">
              {configFilters?.end_on?.map((item) => {
                return (
                  item?.status === "end_on" && (
                    <>
                      <Form.Label style={{ fontSize: 12, margin: 0 }}>
                        {item?.label}
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder={item?.label}
                        name={item?.status}
                        id={item?.status}
                        value={selectedFilters?.endDate}
                        onChange={(e) => handleFilterChange(e, "endDate")}
                      />
                    </>
                  )
                );
              })}
            </Form.Group>
          </div>
        </CustomFilterDrawer>
      )}
    </div>
  );
};

export default ExperimentFilterDrawer;
