import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDateString } from "../../utils/utils";
import "./series-experiments.styles.css";
import { apiGateway } from "../../utils/config";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Delete } from "@mui/icons-material";
import { Form } from "react-bootstrap";
import placeholder from "../../assests/images/placeholder1.png";
import { GET_SERIES_LIST, PUBLISH_EXPERIMENT } from "../../utils/constants";

export default function SeriesExperiments({
  series,
  setErrorMsg,
  alreadyExist,
  setSeries,
  close,
  currentActiveTabValue,
}) {
  const [experiments, setExperiments] = useState([]);
  const [experimentType, setExperimentType] = useState("text");
  const [modalConfirm, setModalConfirm] = useState({
    open: false,
    text: "",
    action: null,
    id: null,
  });
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (alreadyExist) {
      setExperiments([series?.experiment]);
      setExperimentType(series?.experiment?.display_title);
    } else {
      fetchAllExperiments();
    }
  }, []);

  const fetchAllExperiments = async () => {
    const url = `${apiGateway}/api/v1/cms/experiment/series-ctr/${series.slug}/`;
    axiosPrivate
      .get(url)
      .then(({ data, status }) => {
        if (status === 200) {
          setExperiments(data.experiments);
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data
            ? response?.data?.error_message
            : "Unable to load data",
        });
      });
  };

  const deleteExperiment = async (experimentId) => {
    setModalConfirm({ id: null, text: "", open: false, action: null });
    const url = `${apiGateway}/api/v1/cms/experiment/series-ctr/${series.slug}/?experiment_id=${experimentId}`;
    axiosPrivate
      .delete(url)
      .then(({ data, status }) => {
        if (status === 200) {
          setErrorMsg({
            type: "success",
            error: data.status,
          });
          if (data?.status) {
            setExperiments((prev) =>
              prev?.map((experiment) => {
                if (experiment.id === experimentId) {
                  experiment.status = "deleted";
                }
                return experiment;
              })
            );
          }
        }
        close();
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data
            ? response?.data?.error_message
            : "Oops! Failed to delete",
        });
      });
  };

  const handleMakeDefault = async (experimentId) => {
    setModalConfirm({ id: null, text: "", open: false, action: "" });
    const url = `${apiGateway}${PUBLISH_EXPERIMENT}`;
    const body = {
      series_slug: series.slug,
      experiment_id: experimentId,
    };
    axiosPrivate
      .post(url, body)
      .then(({ data, status }) => {
        if (status === 200) {
          setErrorMsg({
            type: "success",
            error: "Experiment made as default",
          });
          setSeries((prev) => ({
            ...prev,
            ...data?.experiment,
            id: series?.id,
          }));
          close();
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data
            ? response?.data?.error_message
            : "Something went wrong",
        });
      });
  };

  return (
    <div className="series-experiments">
      {experiments.length === 0 && (
        <div className="no-experiments"> No Experiments to Show</div>
      )}
      {experiments?.map((experiment, i) => {
        return (
          <>
            {experiment?.display_title?.length > 0 ? (
              <Card className="experiment-card" key={i}>
                <div className="delete-experiment">
                  <div className="experiment-duration">
                    <Chip
                      label={experiment.status.toUpperCase()}
                      className={experiment.status.toLowerCase()}
                    />
                  </div>
                  <div>
                    <Button
                      size="small"
                      className="make-default"
                      onClick={() =>
                        setModalConfirm({
                          open: true,
                          text: "You want to make the selected experiment as Default ?",
                          id: experiment?.id,
                          action: "default",
                        })
                      }
                    >
                      Make Default
                    </Button>
                    {experiment.status.toUpperCase() !== "DELETED" && (
                      <IconButton
                        onClick={() =>
                          setModalConfirm({
                            open: true,
                            text: "Are you sure you want to delete the experiment?",
                            id: experiment?.id,
                            action: "delete",
                          })
                        }
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </div>
                </div>
                <CardContent className="experiment-card-content">
                  <div className="title-experiment-card">
                    <span className="title-exp">Title AB experiment</span>
                    <div className="title-variants-input">
                      <Form.Control
                        type="text"
                        value={series?.display_title}
                        placeholder="Title Variant 1"
                        disabled
                      ></Form.Control>
                      : <span className="title-var">Variant A</span>
                    </div>
                    <div className="title-variants-input">
                      <Form.Control
                        type="text"
                        value={experiment?.display_title}
                        placeholder="Title Variant 1"
                        disabled
                      ></Form.Control>
                      : <span className="title-var">Variant B</span>
                    </div>
                    {experiment?.image && experiment?.image?.length > 0 && (
                      <span className="title-exp mt-2">
                        Thumbnail AB experiment
                      </span>
                    )}
                    {experiment?.image && experiment?.image?.length > 0 && (
                      <div className="images-variants-flex">
                        <div className="images-variants">
                          <img
                            src={series.image ? series.image : placeholder}
                            alt="avatar"
                            className="table-rowserie-thumbnail"
                          />
                          <br />
                          <span className="title-var">Variant A</span>
                        </div>
                        <div className="images-variants">
                          <img
                            src={
                              experiment.image
                                ? experiment.image
                                : experiment.original_image
                                ? experiment.original_image
                                : placeholder
                            }
                            alt="avatar"
                            className="table-rowserie-thumbnail"
                          />
                          <br />
                          <span className="title-var">Variant B</span>
                        </div>
                      </div>
                    )}
                    <div className="experiment-duration-st-end">
                      <div className="start">
                        Start date :{" "}
                        <span className="date-string">
                          {getDateString(
                            new Date(experiment.start_time)
                              .toISOString()
                              .split("T")[0]
                          )}
                        </span>
                      </div>
                      <div className="end">
                        End date :{" "}
                        <span className="date-string">
                          {getDateString(
                            new Date(experiment.end_time)
                              .toISOString()
                              .split("T")[0]
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) : (
              experiment?.image &&
              experiment?.image?.length > 0 && (
                <Card className="experiment-card" key={i}>
                  <div className="delete-experiment">
                    <div className="experiment-duration">
                      <Chip
                        label={experiment.status.toUpperCase()}
                        className={experiment.status.toLowerCase()}
                      />
                    </div>
                    <div>
                      <Button
                        size="small"
                        className="make-default"
                        onClick={() =>
                          setModalConfirm({
                            open: true,
                            text: "You want to make the selected experiment as Default ?",
                            id: experiment?.id,
                            action: "default",
                          })
                        }
                      >
                        Make Default
                      </Button>
                      {experiment.status.toUpperCase() !== "DELETED" && (
                        <IconButton
                          onClick={() =>
                            setModalConfirm({
                              open: true,
                              text: "Are you sure you want to delete the experiment?",
                              id: experiment?.id,
                              action: "delete",
                            })
                          }
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <CardContent className="experiment-card-content">
                    <div className="title-experiment-card">
                      <span className="title-exp">Thumbnail AB experiment</span>
                      <div className="images-variants-flex">
                        <div className="images-variants">
                          <img
                            src={series.image ? series.image : placeholder}
                            alt="avatar"
                            className="table-rowserie-thumbnail"
                          />
                          <br />
                          <span className="title-var">Variant A</span>
                        </div>
                        <div className="images-variants">
                          <img
                            src={
                              experiment.image ? experiment.image : placeholder
                            }
                            alt="avatar"
                            className="table-rowserie-thumbnail"
                          />
                          <br />
                          <span className="title-var">Variant B</span>
                        </div>
                      </div>
                      <div className="experiment-duration-st-end">
                        <div className="start">
                          Start date :{" "}
                          <span className="date-string">
                            {getDateString(
                              new Date(experiment.start_time)
                                .toISOString()
                                .split("T")[0]
                            )}
                          </span>
                        </div>
                        <div className="end">
                          End date :{" "}
                          <span className="date-string">
                            {getDateString(
                              new Date(experiment.end_time)
                                .toISOString()
                                .split("T")[0]
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )
            )}
          </>
        );
      })}

      {currentActiveTabValue === "remake" && (
        <div className="w-[95%] mx-auto">
          <div className="w-fit">
            <Chip
              label={series?.experiment?.status.toUpperCase()}
              className={series?.experiment?.status.toLowerCase()}
            />
          </div>

          <div className="flex overflow-hidden justify-between mt-4">
            <div className="!w-[40%]">
              <p>{series?.id}</p>
              <img
                src={series?.image}
                alt={series?.display_title?.title}
                className="mt-1"
              />
              <p className="mt-2">Control</p>
            </div>

            <div className="!w-[40%]">
              <p>{series?.experiment?.remake_series?.id}</p>
              <img
                src={series?.experiment?.remake_series?.image}
                alt={series?.experiment?.remake_series?.display_title?.title}
                className="mt-1"
              />
              <p className="mt-2">Experiment</p>
            </div>
          </div>

          <div className="flex justify-between mt-4">
            <p> Start date: {series?.experiment?.start_time}</p>
            <p> End date: {series?.experiment?.end_time}</p>
          </div>
        </div>
      )}

      {currentActiveTabValue === "default" && (
        <div className="w-[95%] mx-auto">
          <div className="flex overflow-hidden justify-between mt-4">
            <div className="!w-[40%]">
              <p>Series ID: {series?.id}</p>
              <img src={series?.image} alt={series?.display_title?.title} />
              <p className="mt-2">Remake</p>
            </div>

            <div className="!w-[40%]">
              <p>Series ID: {series?.experiment?.original_series?.id}</p>
              <img
                src={series?.experiment?.original_series?.image}
                alt={series?.display_title?.title}
              />
              <p className="mt-2">Original</p>
            </div>
          </div>
        </div>
      )}

      <Dialog open={modalConfirm?.open}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <p>{modalConfirm?.text}</p>
          <div className="">
            <Button
              variant="contained"
              onClick={() =>
                modalConfirm?.action === "delete"
                  ? deleteExperiment(modalConfirm?.id)
                  : handleMakeDefault(modalConfirm?.id)
              }
              className="mr-2"
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={() => setModalConfirm({ text: "", open: false })}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
