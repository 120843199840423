import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { TEAM_ROLES } from "../utils/constants/Team";
import CreatorDetails from "../components/Creator-Details/creator-details.component";
import SeriesDetails from "../components/Series-Details/series-details.component";
import Notification from "../pages/Notification/notification.component";
import Experiment from "../pages/Experiment/experiment.component";
import CreatorPage from "../pages/Creators/creator.component";
import ProfilePage from "../pages/Profile/profile.component";
import TrackPage from "../pages/Track/Track.component";
import TrackPageList from "../pages/Track/TrackList.component";
import TrackPageEdit from "../pages/Track/TrackEdit.component";
import CreatorDashboard from "../components/Creator-Details/Creator-dashboard";
import ShowsPage from "../pages/Shows/shows.component";
import ShowDetailsComponent from "../components/Show-Details/show-details.component";
import ShowDashboard from "../components/ShowsList/ShowDashboard";
import TrackLogs from "../components/Track/track-logs";
import BlogsPage from "../pages/Blogs";
import BlogDetailed from "../components/Blogs/BlogDetailed";
import EditBlog from "../components/Blogs/EditBlog";
import TeamMembersPage from "../pages/Team";
import FeedBackPage from "../pages/Feedback";
import DashboardLayout from "../components/Common/DashboardLayout";
import Dashboard from "../pages/Dashboard";
import VideoDetailsWithStats from "../components/VideoDetailsWithStats";
import Category from "../pages/Category";
import Login from "../pages/Login/login.component";
import useZustandStore from "../zustandStore/useZustandStore";
import VideosPage from "../pages/Videos";
import ShowTopics from "../pages/ShowTopics";

const AllRoutes = () => {
  const { setGlobalRoles, setInternalTeamAccessGlobalState } =
    useZustandStore();
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const isSuperAdmin = currentUser?.roles?.includes(TEAM_ROLES.superAdmin);
  const isBlogger = currentUser?.roles?.includes(TEAM_ROLES.blogger);
  const isViewer = currentUser?.roles?.includes(TEAM_ROLES.viewer);
  const isEditor = currentUser?.roles?.includes(TEAM_ROLES.editor);
  const isAdmin =
    currentUser?.roles?.includes(TEAM_ROLES.admin) || currentUser?.is_admin;
  const isCreator =
    currentUser?.roles?.includes(TEAM_ROLES.creator) ||
    currentUser?.is_quality_approved;

  // these are the internal team roles who have access for major things,
  const internalTeamAccess = isSuperAdmin || isAdmin || isEditor;

  const hasAccessToSeriesAndShows = internalTeamAccess || isCreator || isViewer;

  const hasAccessToBlogs = internalTeamAccess || isBlogger || isViewer;

  const hasAccessToExperiments = internalTeamAccess || isViewer;

  const hasAccessToCatagories = internalTeamAccess || isViewer;

  const hasAccessToCreators = internalTeamAccess || isViewer;

  const hasAccessToTracks = internalTeamAccess || isViewer;

  const hasAccessToFeedback = internalTeamAccess || isCreator || isViewer;

  const hasAccessToDashboard = isCreator;

  //   setting it up globally in zustand store, so that it can be used all over the platform
  useEffect(() => {
    setInternalTeamAccessGlobalState(internalTeamAccess);
    setGlobalRoles({
      superAdmin: isSuperAdmin,
      admin: isAdmin,
      creator: isCreator,
      editor: isEditor,
      blogger: isBlogger,
      viewer: isViewer,
    });
  }, [currentUser?.roles]);

  return (
    <div>
      <DashboardLayout currentUser={currentUser}>
        <Switch>
          {/* Route checks users role and then redirects to the component on / route */}
          <Route
            exact
            path="/"
            render={() =>
              currentUser ? (
                internalTeamAccess || isViewer ? (
                  <Redirect to="/shows" />
                ) : isCreator ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <Redirect to="/blogs" />
                )
              ) : (
                <Login />
              )
            }
          />

          {/* Profile Page */}
          <Route
            exact
            path="/profile"
            render={() =>
              !currentUser ? <Redirect to="/" /> : <ProfilePage />
            }
          />

          {/* Creator New Dashboard */}
          <Route
            exact
            path="/dashboard"
            render={() =>
              hasAccessToDashboard ? <Dashboard /> : <Redirect to="/" />
            }
          />

          {/* // Shows */}
          <Route
            exact
            path="/shows"
            render={() =>
              hasAccessToSeriesAndShows ? <ShowsPage /> : <Redirect to="/" />
            }
          />

          <Route
            path="/shows/:show_slug"
            render={() =>
              hasAccessToSeriesAndShows ? (
                <ShowDetailsComponent />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            path="/shows-topics/:show_slug"
            render={() =>
              hasAccessToSeriesAndShows ? <ShowTopics /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/show-dashboard/:show_id"
            render={() =>
              !currentUser || !internalTeamAccess ? (
                <Redirect to="/" />
              ) : (
                internalTeamAccess && <ShowDashboard />
              )
            }
          />

          {/* // Old Series route redirect to videos */}
          <Route
            exact
            path="/series"
            render={() => <Redirect to="/videos" />}
          />

          {/* Video */}
          <Route
            exact
            path="/videos"
            render={() =>
              hasAccessToSeriesAndShows ? <VideosPage /> : <Redirect to="/" />
            }
          />

          <Route
            path="/videos/:series_slug"
            render={() =>
              hasAccessToSeriesAndShows ? (
                <SeriesDetails />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            path="/videos-detail/:video_slug"
            render={() =>
              hasAccessToSeriesAndShows ? (
                <VideoDetailsWithStats />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          {/* FEEDBACK */}
          <Route
            exact
            path="/feedback"
            render={() =>
              hasAccessToFeedback ? <FeedBackPage /> : <Redirect to="/" />
            }
          />

          {/* // Experiments */}
          <Route
            exact
            path="/experiment"
            render={() =>
              hasAccessToExperiments ? <Experiment /> : <Redirect to="/" />
            }
          />

          {/* // Creators */}
          <Route
            exact
            path="/creators"
            render={() =>
              hasAccessToCreators ? <CreatorPage /> : <Redirect to="/" />
            }
          />

          <Route
            path="/creators/:creatorId"
            render={() =>
              hasAccessToCreators || isCreator ? (
                <CreatorDetails />
              ) : (
                <Redirect to="/shows" />
              )
            }
          />

          <Route
            exact
            path="/creator-dashboard/:creator_id"
            render={() =>
              !currentUser ? <Redirect to="/" /> : <CreatorDashboard />
            }
          />

          {/* // Tracks */}
          <Route
            exact
            path="/tracks"
            render={() =>
              hasAccessToTracks ? <TrackPageList /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/track-create"
            render={() =>
              hasAccessToTracks ? <TrackPage /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/track-edit/:track_id"
            render={() =>
              hasAccessToTracks ? <TrackPageEdit /> : <Redirect to="/" />
            }
          />

          <Route
            path="/tracks-log/:track_id"
            render={() =>
              hasAccessToTracks ? <TrackLogs /> : <Redirect to="/" />
            }
          />

          {/* // Category Section */}
          <Route
            path="/category"
            render={() =>
              hasAccessToCatagories ? <Category /> : <Redirect to="/" />
            }
          />

          {/* // Blogs listing routing */}
          <Route
            exact
            path="/blogs"
            render={() =>
              hasAccessToBlogs ? <BlogsPage /> : <Redirect to="/" />
            }
          />

          {/* // Create Blog routing */}
          <Route
            exact
            path="/blogs/edit-blog/:blog_slug"
            render={() =>
              hasAccessToBlogs ? <EditBlog /> : <Redirect to="/" />
            }
          />

          {/* // Blog Detailed page routing */}
          <Route
            exact
            path="/blogs/:blog_slug"
            render={() =>
              hasAccessToBlogs ? <BlogDetailed /> : <Redirect to="/" />
            }
          />

          {/* // Team Members only for super admin */}
          <Route
            exact
            path="/team"
            render={() =>
              isSuperAdmin ? <TeamMembersPage /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/notifications/:series_slug"
            render={() =>
              internalTeamAccess ? <Notification /> : <Redirect to="/" />
            }
          />

          {/* <Route
                    exact
                    path="/login"
                    render={() =>
                      !currentUser ? <Redirect to="/" /> : <SeriesPage />
                    }
                  /> */}
        </Switch>
      </DashboardLayout>
    </div>
  );
};

export default AllRoutes;
