import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const CustomModal = ({
  show,
  onHide,
  title,
  className,
  children,
  isButtonsRequired = true,
  handleConfirmation,
  isLoading,
  handleConfirmationBtnText = "Confirm",
}) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 z-[999] bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div
        className={` w-[95%] bg-white rounded-lg shadow-lg flex flex-col md:w-[60%] lg:w-[30%] max-h-[95vh] ${
          className || ""
        }`}
      >
        {/* Modal Header */}
        <div className="px-4 py-3 border-b border-gray-200 flex justify-between items-start gap-x-3">
          <h2 className="text-[17px] font-semibold line-clamp-2">{title}</h2>
          <button onClick={onHide}>✕</button>
        </div>

        {/* Modal Body (Scrollable) */}
        <div className="flex-1 overflow-y-auto p-4">{children}</div>

        {/* Modal Footer */}
        {isButtonsRequired && (
          <div className="px-6 py-3 bg-gray-100 flex justify-end space-x-3 rounded-b-md border-t">
            <button
              onClick={handleConfirmation}
              disabled={isLoading}
              className={`px-4 py-2 text-white rounded-md text-[14px] font-semibold bg-baseBlueColor`}
            >
              {isLoading ? (
                <div className="h-[25px]">
                  <CircularProgress className="text-white mt-1" size={20} />
                  <p className="opacity-0">{handleConfirmationBtnText}</p>
                </div>
              ) : (
                handleConfirmationBtnText
              )}
            </button>
            {onHide && (
              <button
                onClick={onHide}
                className="px-4 py-2 border !bg-white rounded-md font-semibold text-[14px]"
              >
                Cancel
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomModal;
