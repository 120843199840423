import React from "react";
import { Breadcrumbs, Link } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";

export default function CustomBreadcrumbs() {
  const location = useLocation();
  const history = useHistory();
  // these paths are dependent on either slug or id
  const dependentPaths = ["/videos-detail", "/shows-topics"];
  const dependentRoute = ({ path }) =>
    dependentPaths.some((route) => path?.includes(route));

  const handleClick = (event) => {
    event.preventDefault();
    let path = event.currentTarget.getAttribute("data-path");
    // if a route is dependent on slug or id, when clicked on it without dependency just pushing back
    if (dependentRoute({ path })) {
      history.goBack();
    }
    history.push(path);
  };

  const generateBreadcrumbs = () => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const breadcrumbs = [
      { label: "Home", href: "/" }, // Hardcoded Home breadcrumb
    ];

    // Add dynamic pathnames to the breadcrumbs
    pathnames.forEach((pathname, index) => {
      const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
      breadcrumbs.push({ label: pathname, href: routeTo });
    });

    return breadcrumbs.map((breadcrumb, index) => {
      const isLast = index === breadcrumbs.length - 1;

      return isLast ? (
        <div
          key={index}
          className="capitalize !text-[13px] cursor-pointer !p-0 !m-0 !mt-[2.5px]"
        >
          {breadcrumb.label}
        </div>
      ) : (
        <Link
          key={index}
          underline="hover"
          sx={{ color: "white" }}
          data-path={breadcrumb.href}
          onClick={handleClick}
          className="!text-white capitalize !text-[13px] cursor-pointer opacity-[85%]"
        >
          {breadcrumb.label}
        </Link>
      );
    });
  };

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb" className="!text-white">
        {generateBreadcrumbs()}
      </Breadcrumbs>
    </div>
  );
}
