const topicDetailsState = { title: "", date: "", reference: "" };
const topicsMissingFields = { title: false, date: false };

const handleTopicsTableHeadline = ({ hasAccessToActions }) => {
  const heading = [
    {
      title: "Topic",
      display: true,
    },
    {
      title: "Created On",
      display: true,
    },
    {
      title: "Publish Date",
      display: true,
    },
    {
      title: "Reference",
      display: true,
    },
    {
      title: "Status",
      display: true,
    },
    {
      title: "Actions",
      display: hasAccessToActions,
    },
  ];
  return heading;
};

const handleTopicsStatusColor = ({ status }) => {
  switch (true) {
    case status === "approved":
      return `!text-green-500 !bg-green-100`;
    case status === "waiting_for_approval":
      return `!text-blue-500 !bg-blue-100`;
    case status === "declined":
      return `!text-red-500 !bg-red-200`;
    case status === "deleted":
      return `!text-red-500 !bg-red-100 !line-through`;
    default:
      return "!text-white !bg-gray-400";
  }
};

export {
  topicDetailsState,
  topicsMissingFields,
  handleTopicsTableHeadline,
  handleTopicsStatusColor,
};
