import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

const LineGraph = ({ data, videoDurationInSeconds, className }) => {
  // Prepare the x-axis labels (video duration)
  const xLabels = data?.map((item) => parseFloat(item.seconds.toFixed(1)));
  // Prepare the y-axis data (percent users)
  const yData = data?.map((item) => parseFloat(item.percent_users.toFixed(1)));

  const chartData = {
    labels: xLabels,
    datasets: [
      {
        label: "User Retention (%)",
        data: yData,
        fill: false,
        borderWidth: 1.5,
        borderColor: "rgb(53,74,255)",
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 0,
        pointHitRadius: 0,
      },
    ],
  };

  // Calculate dynamic desiredTicks based on video duration
  let desiredTicks;

  if (videoDurationInSeconds <= 120) {
    // For videos up to 2 minutes
    desiredTicks = 6; // More ticks for short videos
  } else if (videoDurationInSeconds <= 300) {
    // For videos up to 5 minutes
    desiredTicks = 5; // Moderate ticks for medium-length videos
  } else if (videoDurationInSeconds <= 600) {
    // For videos up to 10 minutes
    desiredTicks = 4; // Fewer ticks for longer videos
  } else {
    desiredTicks = 3; // Even fewer ticks for very long videos
  }

  // Determine the step size for the x-axis dynamically
  const dynamicStepSize = Math.ceil(videoDurationInSeconds / desiredTicks);

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        intersect: false,
        displayColors: false,
        callbacks: {
          label: function (tooltipItem) {
            // Round percent_users to 1 decimal place
            const roundedPercentUsers = parseFloat(
              data[tooltipItem.dataIndex].percent_users
            ).toFixed(1);

            return `${roundedPercentUsers}%: users are watching at ${
              data[tooltipItem.dataIndex].seconds
            } sec`;
          },
          title: function () {
            return "";
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: "linear", // Ensures the x-axis treats values as numbers
        title: {
          display: true,
          text: "Duration (Seconds) \u2192",
        },
        ticks: {
          max: videoDurationInSeconds, // Ensure max is set to the actual video duration
          stepSize: dynamicStepSize, // Use dynamic step size
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value; // If the value is an integer, show without decimals
            }
            return value.toFixed(1); // For non-integer values, show 1 decimal place
          },
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 20, // Step size for even percentages (20%)
          callback: function (value) {
            if (value % 20 === 0) {
              return `${value}%`;
            }
            return "";
          },
        },
      },
    },
  };

  return <Line data={chartData} options={options} className={className} />;
};

export default LineGraph;
