import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./creator-details.styles.css";
import { Row, Col, Container } from "react-bootstrap";
import {
  getDateString,
  handleDefaultProfile,
  validateImage,
} from "../../utils/utils";
import { connect } from "react-redux";
import { setHasMore } from "../../redux/video/video.action";
import { setHasMore as setHasMoreSeries } from "../../redux/series/series.action";
import Loader from "../../components/Loader/index";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Form } from "react-bootstrap";
import UpdateContentManager from "../Update-Content-Manager/update-content-manager";
import Tooltip from "@mui/material/Tooltip";
import { changeQualityApprovedStartAsync } from "../../redux/creator/creator.action";
import EditIcon from "@mui/icons-material/Edit";
import {
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { faEdit, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { apiGateway } from "../../utils/config";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { GET_USER, USER_V1 } from "../../utils/constants";
import { LineAxis } from "@mui/icons-material";
import {
  fetchCurrentUserFailure,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
} from "../../redux/user/user.action";
import { store } from "../../redux/store";
import VideoListing from "../VideoListing";
import useZustandStore from "../../zustandStore/useZustandStore";

const tabsList = Object.freeze({
  SERIES: "SERIES",
  VIDEOS: "VIDEOS",
});

function CreatorDetails({
  history,
  match,
  setHasMore,
  setHasMoreSeries,
  changeQualityApproved,
  ...remainingProps
}) {
  var creatorId = match.params.creatorId;
  const axiosPrivate = useAxiosPrivate();
  const [creator, setCreator] = useState(null);
  const [tabSelected, setTabSelected] = useState(tabsList.SERIES);
  const gendersData = [
    { id: null, name: "Not Selected" },
    { id: "m", name: "male" },
    { id: "f", name: "female" },
    { id: "t", name: "others" },
  ];
  //PROFILE PAGE OF USER STATES
  const [showEdit, setShowEdit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [scrollDirection, setScrollDirection] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [status, setStatus] = useState("");
  const [gender, setGender] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [genders, setGenders] = useState(gendersData);
  const [showContentManager, setShowContentManager] = useState(false);
  const [qualityApproved, setQualityApproved] = useState();
  const [showQualityApprovedToggle, setShowQualityApprovedToggle] =
    useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "success" });
  const globalRoles = useZustandStore((val) => val?.globalRoles);
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );

  const hasAccessToPerformance =
    internalTeamAccessGlobalState || globalRoles?.viewer;

  const fetchCreator = async () => {
    var creatorId = match.params.creatorId;
    if (remainingProps["isOnUserProfilePage"]) {
      creatorId = remainingProps["profileDataOfUser"].id;
    }
    // NEED CONTENT MANGER IN THIS API
    const url = `${apiGateway}${USER_V1}${creatorId}/units/`;
    axiosPrivate
      .get(url)
      .then(({ data, status }) => {
        if (status === 200) {
          setCreator(data?.profile);
          setQualityApproved(data.profile?.is_quality_approved);
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data
            ? response?.data?.error_message
            : "Something went wrong",
        });
      });
  };

  useEffect(() => {
    fetchCreator();
  }, []);

  const showContentManagerModal = () => {
    setShowContentManager(true);
  };

  const handleGetUpdatedUserData = async () => {
    try {
      store.dispatch(fetchCurrentUserStart());
      const url = `${apiGateway}${GET_USER}`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        store.dispatch(fetchCurrentUserSuccess(response?.data?.user));
      }
    } catch (error) {
      store.dispatch(fetchCurrentUserFailure());
    }
  };

  // UPDATE THE FORM
  const handleUpdate = async () => {
    const data = new FormData();
    let name = firstName;
    name += lastName ? ` ${lastName}` : "";
    const userId =
      remainingProps["profileDataOfUser"]?.id || match.params.creatorId;
    data.append("name", name);
    if (avatar) {
      const isValidThumbnail = validateImage(avatar.name);
      if (!isValidThumbnail) {
        setErrorMsg({
          type: "failed",
          error: "Please select a valid avatar of format png, jpeg, jpg",
        });
        return;
      }
      data.append("avatar", avatar);
    }
    data.append("status", status);
    data.append("gender", gender);
    data.append("email", email);
    const url = `${apiGateway}${USER_V1}${userId}/update/`;
    axiosPrivate
      .post(url, data)
      .then(({ data, status }) => {
        if (status === 200) {
          setErrorMsg({
            type: "success",
            error: "User Updated successfully",
          });
          handleGetUpdatedUserData();
          setShowEdit(false);
          fetchCreator();
        }
      })
      .catch((error) => {
        setErrorMsg({
          type: "error",
          error: error?.response?.data?.error_message || error?.message,
        });
      });
  };

  const showData = (data) => {
    if (data) return data;
    else return "";
  };

  const editHandle = () => {
    const name = showData(creator.name);
    const spaceIndex = name.indexOf(" ");
    const firstName = spaceIndex >= 0 ? name.slice(0, spaceIndex) : name;
    const lastName =
      spaceIndex >= 0 ? name.slice(spaceIndex + 1, name.length) : "";
    setFirstName(firstName);
    setLastName(lastName);
    setThumbnail(creator.avatar);
    setEmail(creator?.email);
    setShowEdit(true);
    setStatus(showData(creator?.status));
  };

  //Quality Approved Methods

  const toggleQualityApproved = () => {
    changeQualityApproved(creator.id, !qualityApproved, setErrorMsg);
    setQualityApproved(!qualityApproved);
    setShowQualityApprovedToggle(false);
  };

  var documentS;
  if (document !== "undefined") {
    documentS = document?.getElementById("creator-deatils");
  }
  useEffect(() => {
    const onScroll = () => {
      setScrollDirection((scrollDirection) => {
        if (
          !scrollDirection &&
          (document?.body.scrollTop > 10 || documentS.scrollTop > 10)
        ) {
          return true;
        }

        if (
          scrollDirection &&
          document?.body.scrollTop < 4 &&
          documentS.scrollTop < 4
        ) {
          return false;
        }

        return scrollDirection;
      });
    };
    if (documentS) {
      documentS.addEventListener("scroll", onScroll);
    }
    return () => documentS && documentS.removeEventListener("scroll", onScroll);
  }, [documentS]);

  const handleAnalytics = () => {
    window.location.href = `#/creator-dashboard/${creator.id}`;
  };
  return (
    <>
      {creator ? (
        <div className="mt-3">
          <AlertComponent
            message={errorMsg?.error}
            type={errorMsg?.type}
            setAlertNotification={() =>
              setErrorMsg({ error: "", type: "failed" })
            }
          />
          <div className="creator-deatils" id="HideScroll">
            <div className="bg-white flex">
              <Container fluid>
                <Row className="profile-details-container">
                  <Col
                    className="profile-details-container-image"
                    md={2}
                    style={{ position: "relative" }}
                  >
                    {remainingProps["profileDataOfUser"] && (
                      <IconButton
                        className="editprodile"
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          // Split the name into first and last name
                          editHandle();
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <div className="avatar" style={{ position: "relative" }}>
                      <img
                        src={
                          creator.avatar ||
                          handleDefaultProfile({ userName: creator?.name })
                        }
                        alt={creator?.name}
                      />
                      <div className="occupation-img-bar-wrapper">
                        <span className="occupation-img-bar">
                          {showData(creator?.user_persona)}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col
                    className="d-flex align-items-center profile-details-container-data ml-0 mt-0"
                    md={9}
                  >
                    <div>
                      <div className="creator-bio">
                        <span className="creator-name-profile mt-1 ">
                          {showData(creator.name.toUpperCase())}
                        </span>{" "}
                        <p className="creator-name-status mt-0 ml-0 mb-0">
                          {showData(creator?.status)?.length > 400
                            ? showData(creator?.status).substr(0, 400) + "..."
                            : showData(creator?.status)}{" "}
                        </p>
                      </div>
                      <div className="creator-stats d-flex my-1">
                        <div className="creator-profile-stats mt-0">
                          <span>Series</span>
                          <br /> {showData(creator.n_series)}
                        </div>
                        <div className="creator-profile-stats mt-0">
                          <span>Videos</span>
                          <br /> {showData(creator.n_videos)}{" "}
                        </div>
                        {/* <div className="creator-profile-stats mt-0">
        <span>Views</span><br/> {showData(creator.n_views)}{" "}
      </div> */}
                        <Tooltip
                          title="Click to change the content manager."
                          arrow
                        >
                          <div className="creator-profile-stats mt-0">
                            <span>
                              Content Manager{" "}
                              <FontAwesomeIcon
                                className="edit-icon"
                                icon={faEdit}
                                color="#777"
                                onClick={() => {
                                  showContentManagerModal();
                                }}
                              />
                            </span>
                            <br />{" "}
                            {showData(
                              creator?.content_manager
                                ? creator?.content_manager?.name
                                : ""
                            )}{" "}
                          </div>
                        </Tooltip>
                      </div>
                      <div className="creator-stats d-flex mt-0">
                        <p className="creator-profile-stats mt-0">
                          <span>Email </span> <br />
                          {showData(creator.email)}
                        </p>
                        <p className="creator-profile-stats mt-0">
                          <span>Phone </span> <br />
                          {showData(creator.phone)}
                        </p>
                        <p className="creator-profile-stats mt-0">
                          <span>Signup on</span>
                          <br /> {showData(
                            getDateString(creator.signedup_on)
                          )}{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>

              <div className="flex flex-col gap-y-5 z-0 p-2">
                <Button
                  className="performance-cta"
                  onClick={() => editHandle()}
                >
                  <FontAwesomeIcon icon={faUserAlt} className="mr-1" /> Update
                  Profile
                </Button>

                {hasAccessToPerformance && (
                  <Button
                    className="performance-cta !border"
                    onClick={handleAnalytics}
                  >
                    <LineAxis /> Performance
                  </Button>
                )}
              </div>
            </div>
            {/* )} */}
            <div className="mt-2">
              <VideoListing
                isShowUploadCta={false}
                isOnCreatorDetailsPage={{
                  value: true,
                  creatorId: creatorId || creator?.id,
                }}
              />
            </div>
          </div>
          <div>
            {" "}
            {/* EDIT FORM FOR THE USER */}
            {showEdit && (
              <Modal show={showEdit}>
                <Modal.Header
                  className="modal-header"
                  closeButton
                  closeVariant
                  onHide={() => setShowEdit(false)}
                >
                  Edit Profile
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Form>
                      {/* {thumbnail && ( */}
                      <div className="center">
                        <img
                          src={
                            avatar
                              ? URL.createObjectURL(avatar)
                              : thumbnail ||
                                handleDefaultProfile({
                                  userName: firstName + lastName,
                                })
                          }
                          className="thumbnail-form-image-avatr object-contain"
                          alt={firstName || "Seekho User"}
                        />
                      </div>
                      {/* )} */}
                      <Form.Group>
                        {/* <Form.Label>Avatar</Form.Label> */}
                        <div className="d-flex justify-content-center text-center w-100">
                          <Form.Control
                            id="user-account-image"
                            label=""
                            title=""
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            name="avatar"
                            className="x-50 d-none"
                            onChange={(e) => setAvatar(e.target.files[0])}
                          ></Form.Control>
                          <label
                            htmlFor="user-account-image"
                            className="label-for-image-select"
                          >
                            <EditIcon className="mx-2" />
                            Edit Image
                          </label>
                        </div>
                      </Form.Group>

                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        placeholder="Enter your First Name"
                        required
                        name="firstName"
                        min="1"
                        max="30"
                        className="w-100 my-2"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        type="text"
                        placeholder="Enter your last name"
                        required
                        name="lastName"
                        min="1"
                        max="150"
                        className="w-100 my-2"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />

                      {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="status"
                          value={status}
                          rows={3}
                          onChange={(e) => setStatus(e.target.value)}
                        />
                      </Form.Group> */}

                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        placeholder="Enter your email"
                        required
                        name="email"
                        min="1"
                        max="30"
                        className="w-100 my-2"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <TextField
                        id="outlined-multiline-flexible"
                        label="Status"
                        name="status"
                        multiline
                        rows={6}
                        value={status}
                        className="w-100 my-2"
                        onChange={(e) => setStatus(e.target.value)}
                      />

                      <Form.Group>
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                          as="select"
                          name="category"
                          className="w-100 py-2"
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                        >
                          {genders?.map((selectedGender) => (
                            <option
                              selected={gender === selectedGender.id}
                              key={selectedGender.id}
                              value={selectedGender.id}
                            >
                              {selectedGender.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form>
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="contained"
                        className="update-btn-profile"
                        type="submit"
                        onClick={handleUpdate}
                      >
                        Update
                      </Button>
                    </div>
                  </Container>
                </Modal.Body>
              </Modal>
            )}
          </div>
          <Modal
            show={showContentManager}
            className="content-manager-modal p-5"
            centered
          >
            <UpdateContentManager
              creator={creator}
              setErrorMsg={setErrorMsg}
              close={() => {
                setShowContentManager(false);
              }}
            />
          </Modal>
          <Dialog
            open={showQualityApprovedToggle}
            onClose={() => setShowQualityApprovedToggle(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {qualityApproved
                ? `Do you want to unmark ${creator.name} from quality approved ?`
                : `Do you want to mark ${creator.name}  as quality approved ?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description"></DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowQualityApprovedToggle(false)}>
                No
              </Button>
              <Button onClick={toggleQualityApproved} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div className="center-loader-screen">
          <Loader />
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setHasMore: (hasMore) => dispatch(setHasMore(hasMore)),
  setHasMoreSeries: (hasMore) => dispatch(setHasMoreSeries(hasMore)),
  changeQualityApproved: (creatorId, isQualityApproved, setErrorMsg) =>
    dispatch(
      changeQualityApprovedStartAsync(creatorId, isQualityApproved, setErrorMsg)
    ),
});

export default withRouter(connect(mapDispatchToProps)(CreatorDetails));
