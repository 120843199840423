const experimentsTabs = [
  {
    id: "Series CTR",
    key: "series-ctr",
    index: 0,
    show: true,
    value: "ctr",
  },
  {
    id: "Series Remake",
    key: "series-remake",
    index: 1,
    show: true,
    value: "remake",
  },
  {
    id: "Series Default",
    key: "default-remake-series",
    index: 2,
    show: true,
    value: "default",
  },
];

const handleExperimentTableHeading = ({ isDefaultRemakeSeriesTab }) => {
  const table = [
    { id: 1, title: "Thumbnail", display: true },
    { id: 2, title: "Title", display: true },
    { id: 3, title: "ID", display: true },
    { id: 4, title: "Start", display: !isDefaultRemakeSeriesTab },
    { id: 5, title: "End", display: !isDefaultRemakeSeriesTab },
    { id: 5, title: "Replace Date", display: isDefaultRemakeSeriesTab },
    { id: 6, title: "Status", display: true },
    { id: 7, title: "Actions", display: true },
  ];
  return table;
};

const handleConvertTabString = ({ tabValue }) => {
  if (tabValue === 0) return "series-ctr";
  if (tabValue === 1) return "series-remake";
  if (tabValue === 2) return "default-remake-series";
};

const filtersDefaultState = {
  status: [],
  startDate: [],
  endDate: [],
  // Add more filter categories here if needed in the future
};

export {
  experimentsTabs,
  filtersDefaultState,
  handleExperimentTableHeading,
  handleConvertTabString,
};
